<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>비밀번호변경</agent-sub-title>


    <div class="gameinfo">



      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <div style="width: 34%">
          <h5 style="text-align: center">현재비밀번호</h5>
          <el-input size="mini" v-model="agent.passwd"></el-input>
        </div>
      </div>

      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <div style="width: 34%">
          <h5 style="text-align: center">새비밀번호</h5>
          <el-input size="mini" v-model="agent.newPasswd"></el-input>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <div style="width: 34%">
          <h5 style="text-align: center">새비밀번호확인</h5>
          <el-input size="mini" v-model="checkNewPassword"></el-input>
        </div>
      </div>
<!--      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">-->
<!--        <div style="width: 34%">-->
<!--          <h5 style="text-align: center">환전계좌번호</h5>-->
<!--          <el-input size="mini" v-model="agent.acNo"></el-input>-->
<!--        </div>-->
<!--      </div>-->

      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <el-button @click="changePassword()">변경하기</el-button>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">

    </div>
  </div>

</template>

<script>

import {Loading} from 'element-ui';
import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "@/common/agent/agentMixin";
import {changeAgentPassword} from "@/network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";

export default {
  name: "AgentChangePassword",
  components: {
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      agent: {},
      checkNewPassword: '',
      fullscreenLoading: false,
    }
  },
  methods: {
    changePassword() {
      if(this.agent.newPasswd !== this.checkNewPassword){
        this.$message.error("입력하신 새 비번이 동일하지 않습니다");
        return false;
      }
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      changeAgentPassword(this.agent).then(res => {
        loadingInstance.close()
        if (res.data.success) {
          this.$message.success("비밀번호변경이 완료되였습니다");
          this.agent={}
          this.checkNewPassword='';
        } else {
          this.$message.error(res.data.msg);
        }
      })

    }
  },
  created() {

  }
}
</script>

<style scoped>
.table50 {
  border: 2px solid #0c0e0e;
  margin-top: 5px;
  font-weight: 600;
}

.data {
  width: 100%;
  height: calc(100vh - 185px);
  overflow-y: hidden;
  align-items: flex-start;
}

.gameinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #b3d8ff;
  padding: 10px;

}

.g1 {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #b3d8ff;
  padding: 5px;
  border-radius: 3px;
}

</style>